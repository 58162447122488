import React from 'react'
import PrimaryLayout from '../layouts/PrimaryLayout'
import Hero from '../components/Hero/Hero'
import PagePadding from '../components/PagePadding/PagePadding'
import CoursePreview from '../components/CoursePreview/CoursePreview'
import EventItemFilter from '../components/EventItemFilter/EventItemFilter'
import ReviewCarousel from '../components/ReviewCarousel/ReviewCarousel'
import { useStaticQuery, graphql } from 'gatsby'

import config from 'react-reveal/globals'
import '../styles/normalize.scss'
import '../styles/variables.scss'
import '../styles/fonts.scss'
import '../styles/global.scss'
import '../styles/link.scss'
import InfoBlockCourse from '../components/InfoBlockCourse/InfoBlockCourse'
import ReactHtmlParser from 'react-html-parser'
import { encodeToReadableUrl } from '../utils/encodeToReadableUrl'
import { formatDate } from '../components/EventItems/EventItems'

config({ ssrFadeout: true })

export default function Zomer() {
	const {
		allWordpressWpReview,
		allWordpressWpCursus,
		allWordpressWpOverigeactiviteiten,
		allWordpressWpReis,
		alleReizen,
		summerWordpressPage,
	} = useStaticQuery(graphql`
		{
			summerWordpressPage: wordpressPage(wordpress_id: { eq: 563 }) {
				id
				acf {
					text
				}
			}
			allWordpressWpReview {
				nodes {
					acf {
						age
						level
						name
						review
					}
				}
			}
			allWordpressWpCursus(
				filter: {
					acf: {
						subject: {
							in: ["Mountainbiken", "Skeeleren", "Droogtrainen"]
						}
					}
				}
			) {
				nodes {
					acf {
						title_course
						subject
						start_date
						end_date
						price
						category
						pictures {
							alt_text
							source_url
						}
					}
					wordpress_id
					link
				}
			}
			allWordpressWpOverigeactiviteiten {
				nodes {
					acf {
						title
						subject
						category
					}
					wordpress_id
					link
				}
			}
			allWordpressWpReis(
				limit: 1
				filter: { acf: { seizoenCursus: { eq: "Zomer" } } }
			) {
				nodes {
					acf {
						reis_id
						reis_promotie {
							punt_1
							punt_2
							punt_3
							punt_4
							tonen
						}
						intro_text
						reis_blok {
							reis_beschrijving
							reis_title
						}
						sfeer_fotos {
							alt_text
							source_url
							localFile {
								url
								childImageSharp {
									# Try editing the "maxWidth" value to generate resized images.
									fluid(maxWidth: 600, maxHeight: 600) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
					wordpress_id
					link
				}
			}
			alleReizen: allWordpressWpReis(
				filter: { acf: { seizoenCursus: { eq: "Zomer" } } }
			) {
				nodes {
					acf {
						reis_id
						cursusnaam
						intro_text
						reis_blok {
							reis_beschrijving
							reis_title
						}
						sfeer_fotos {
							alt_text
							source_url
						}
						reis_informatie_blok {
							prijs
							aantal_dagen
							datums {
								eind_datum
								gegarandeerd_of
								start_datum
							}
						}
					}
					wordpress_id
					link
				}
			}
		}
	`)

	const showVacationPromo =
		allWordpressWpReis.nodes.length &&
		allWordpressWpReis.nodes[0].acf.reis_promotie.tonen

	const coursePreviewData = {
		image1: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[0],
			alt:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[0].alt_text,
		},
		image2: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[1],
			alt:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[1].alt_text,
		},
		image3: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[2],
			alt:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[2].alt_text,
		},
		image4: {
			src:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[3],
			alt:
				allWordpressWpReis.nodes.length &&
				allWordpressWpReis.nodes[0].acf.sfeer_fotos[3].alt_text,
		},
		incentive: {
			text:
				allWordpressWpReis.nodes.length &&
				ReactHtmlParser(
					allWordpressWpReis.nodes[0].acf.reis_blok.reis_beschrijving,
				),
		},
		article: {
			title: 'Wat je kunt verwachten',
			excerpt:
				allWordpressWpReis.nodes.length &&
				ReactHtmlParser(allWordpressWpReis.nodes[0].acf.intro_text),
			list: allWordpressWpReis.nodes.length && [
				allWordpressWpReis.nodes[0].acf.reis_promotie.punt_1,
				allWordpressWpReis.nodes[0].acf.reis_promotie.punt_2,
				allWordpressWpReis.nodes[0].acf.reis_promotie.punt_3,
				allWordpressWpReis.nodes[0].acf.reis_promotie.punt_4,
			],
			button: {
				text: 'BELEEF DE REIS',
				to: `/vakantie/${encodeToReadableUrl(
					allWordpressWpReis.nodes[0].link,
				)}${allWordpressWpReis.nodes[0].wordpress_id}/`,
			},
		},
	}

	const review = allWordpressWpReview.nodes.map((review) => ({
		quote: review.acf.review,
		cite: {
			age: review.acf.age,
			name: review.acf.name,
			experience: review.acf.level,
		},
	}))

	const eventItems = allWordpressWpCursus.nodes.map((course) => ({
		name: course.acf.title_course,
		to: `/zomer/cursus/${encodeToReadableUrl(course.link)}${
			course.wordpress_id
		}/`,
		price: `${course.acf.price !== null ? course.acf.price : ''}`,
		period: `${formatDate(course.acf.start_date)} - ${formatDate(
			course.acf.end_date,
		)}`,
		category: `${course.acf.category}`,
		type: 'Cursus',
	}))

	const obj = {
		title: 'In Balans Alkmaar',
		course_description: summerWordpressPage.acf.text,
		onlyText: true,
		homepage: true,
	}

	const otherEvents = allWordpressWpOverigeactiviteiten.nodes.map(
		(event) => ({
			name: event.acf.title,
			to: `/activiteit/${encodeToReadableUrl(event.link)}${
				event.wordpress_id
			}/`,
			// price: `${course.acf.price}`,
			// period: `${course.acf.start_date} - ${course.acf.end_date}`,
			category: `${event.acf.category}`,
			type: 'Event',
		}),
	)

	const allTrips = alleReizen.nodes.map((trip) => ({
		name: `${trip.acf.reis_blok.reis_title} - ${trip.acf.reis_informatie_blok.aantal_dagen} dagen`,
		to: `/vakantie/${encodeToReadableUrl(trip.link)}${trip.wordpress_id}/`,
		period: `${trip.acf.reis_informatie_blok.datums[0].start_datum} - ${trip.acf.reis_informatie_blok.datums[0].eind_datum}`,
		price: `${trip.acf.reis_informatie_blok.prijs}`,
		category: `Sportvakantie`,
		type: 'Reis',
	}))

	const allActivities = eventItems.concat(otherEvents).concat(allTrips)

	return (
		<PrimaryLayout>
			<Hero />
			{showVacationPromo && <CoursePreview data={coursePreviewData} />}
			<PagePadding>
				<InfoBlockCourse className={'home'} course={obj} />
			</PagePadding>

			<PagePadding>
				<EventItemFilter events={allActivities} />
			</PagePadding>

			<ReviewCarousel className={'review__carousel'} cards={review} />
		</PrimaryLayout>
	)
}
